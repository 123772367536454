var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{staticClass:"mb-0",attrs:{"no-body":""}},[_c('div',{staticClass:"m-2"},[_c('b-row',[_c('b-col',{staticClass:"d-flex align-items-center justify-content-start mb-1 mb-md-0",attrs:{"cols":"12","md":"6"}},[_c('label',[_vm._v("Show")]),_c('label',[_vm._v("entries")])]),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"d-flex align-items-center justify-content-end"},[_c('b-form-input',{staticClass:"d-inline-block mr-1",attrs:{"placeholder":"Search..."},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}}),_c('b-button',{attrs:{"to":{name:'add-new-client',
              params:{
              addNew:true
              }},"variant":"primary"}},[_c('span',{staticClass:"text-nowrap"},[_vm._v("Add Client")])])],1)])],1)],1)]),(_vm.loading)?_c('Loader'):_c('b-card',{staticStyle:{"padding":"0rem"}},[_c('b-card-body',{staticStyle:{"padding":"0rem"}},[_c('b-table',{staticClass:"position-relative",staticStyle:{"text-align":"center","padding":"0rem"},attrs:{"items":_vm.state.invoices ? _vm.state.invoices : [],"responsive":"","fields":_vm.invoiceListFields,"primary-key":"id","busy":_vm.state.loading,"show-empty":"","empty-text":"No matching records found"},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-danger my-2"},[_c('loader')],1)]},proxy:true},{key:"cell(image)",fn:function(data){return [_c('b-media',{attrs:{"vertical-align":"center"},scopedSlots:_vm._u([{key:"aside",fn:function(){return undefined},proxy:true}],null,true)},[_c('span',{staticClass:"font-weight-bold d-block text-nowrap"},[(data.item.ClientSetting&&data.item.ClientSetting.logo)?_c('img',{staticStyle:{"height":"100px","width":"100px"},attrs:{"src":data.item.ClientSetting.logo}}):_vm._e()])])]}},{key:"cell(invoiceNumber)",fn:function(data){return [_c('b-link',{attrs:{"to":{
              name:'settings-page',
              params:{
                id:data.item.invoiceGuid
              }
            }}},[_c('span',[_vm._v(_vm._s(data.item.invoiceNumber))])])]}},{key:"cell(actions)",fn:function(data){return [_c('b-button',{attrs:{"type":"submit","variant":".btn-flat-dark","to":{
              name:'edit-client',
              params:{
                id:data.item.id
              }}}},[_c('edit-icon',{staticClass:"custom-class text-primary",attrs:{"size":"1.5x"}})],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }